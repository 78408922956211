.navbar {
  position: relative;
}
.navbar .container {
  min-height: 72px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d-block {
  display: block;
}
.navbar .navLogo {
  display: flex;
  align-items: center;
  color: var(--orange-color);
}
.navbar .navLogo .LOGO {
  margin-right: 10px;
}
.navbar .navLogo span {
  text-transform: uppercase;
  font-weight: 800;
}
.navbar .navbarLinks .closeBtn {
  display: none;
}
.navbar .navbarLinks ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
}
@media (max-width: 998px) {
  .navbar .navbarLinks ul {
    /* display: none; */
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 90px;
    left: 0;
    background-color: rgb(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    transition: 0.3s;
  }
  .navbar .navbarLinks ul li {
    margin-right: auto;
  }
  .navbar .navbarLinks ul li a {
    color: white !important;
  }
  .navbar .navbarLinks ul li a:hover {
    color: var(--orange-color) !important;
  }
  .closeBtn {
    position: absolute;
    right: 10px;
    top: 90px;
    cursor: pointer;
    transition: 0.3s;
  }
}
.closeBtn {
  /* display: none; */
  opacity: 0;
  z-index: 1000;
  color: #fff;
  font-size: 40px;
}
.navbar .navbarLinks ul li {
  padding: 0.8rem 1.3rem;
}
.navbar .navbarLinks ul li a {
  color: var(--link-color);
  transition: 0.2s;
}
.navbar .navbarLinks ul li a:hover {
  color: var(--orange-color);
}
@media (min-width: 768px) {
  .tith-icon {
    display: none;
  }
}
@media (max-width: 998px) {
  .tith-icon {
    display: block;
    font-size: 22px;
    cursor: pointer;
  }
}

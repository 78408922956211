footer {
  background-color: var(--orange-color);
  color: white;
  padding: 50px 0;
  overflow: hidden;
}
footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
}
footer .container .logo {
  padding-right: 15px;
  font-weight: 800;
  font-size: 32px;
  cursor: pointer;
}
footer .container .logo-right {
  display: flex;
  align-items: center;
}
footer .container .social-media {
  display: flex;
  align-items: center;
  color: white;
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  footer .container .logo-right {
    margin: auto;
    border-bottom: 1px solid white;
  }
  footer .container .social-media {
    margin: auto;
  }
  footer .container .social-media .social-card {
    margin: 0;
    padding: 10px;
  }
}
footer .container .social-media .social-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
}
footer .container .social-media .social-card .icon {
  margin-bottom: 20px;
}

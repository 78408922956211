.about {
  background-color: var(--orange-color);
  padding: 50px 0;
}
/* .about .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
  grid-gap: 20px;
} */
@media (max-width: 767px) {
  .about .container .experience {
    padding-bottom: 60px;
  }
}
.about .container .experience h6 {
  color: #fff;
  padding: 20px 0;
}
.about .container .experience .button {
  padding: 0.8rem 1.6rem;
  background: #fff;
  color: #000;
  border: none;
  outline: none;
  border-radius: 26px;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 800;
}
.about .container .experience .button:hover {
  outline: 1px solid #fff;
  background: var(--orange-color);
  color: #fff;
}
.about .container .skills .progress-skills {
  margin: 5px 0;
}
.about .container .skills .progress-skills span {
  font-weight: normal;
  font-size: 24px;
  color: #fff;
  display: block;
  padding: 0px 0 5px 0;
}
.about .container .skills .progress-skills .progress {
  background-color: rgb(0 0 0 / 15%);
  height: 30px;
  border-radius: 4px;
}
.about .container .skills .progress-skills .progress-bar {
  border-radius: 4px 0 0 4px;
  background-color: #fff;
  height: 30px;
  font-size: 18px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about .container .title {
  color: #fff;
  font-size: 32px;
}
.about .container .description {
  color: #ffffffaf;
  line-height: 1.8;
  margin-bottom: 30px;
}

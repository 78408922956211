.gallery {
  padding: 50px 0;
}
.gallery .links ul {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  padding: 30px 0;
}
.gallery .links ul li {
  padding: 0.8rem 1.6rem;
}
.gallery .links ul li a {
  color: var(--link-color);
  font-weight: 800;
  padding: 10px 15px;
  border-radius: 6px;
}
@media screen and (max-width: 767px) {
  .gallery .links ul li a {
    font-size: 12px;
  }
}
.gallery a img {
  margin-top: 10px;
  max-width: 100%;
}
.gallery .links ul li a:hover {
  color: var(--orange-color);
}
.holder {
  margin-left: 0;
}
body,
html {
  height: 100%;
  margin: 0;
  font-family: Arial;
}

/* Style tab links */
.tablink {
  background-color: #555;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 25%;
}

.tablink:hover {
  background-color: #777;
}

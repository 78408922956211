.t--card {
  padding: 50px;
  border-radius: 8px;
  background-color: rgb(233, 233, 233);
  cursor: default;
  box-shadow: 3px 4px 13px 0px #00000094;
}
@media screen and (max-width: 767px) {
  .t--card {
    width: 100%;
    margin: 20px 0;
  }
}
.t--card .header {
  display: flex;
  align-items: center;
}
.t--card .header .icon {
  font-size: 50px !important;
}
.t--card .header img {
  border-radius: 50%;
  width: 80px;
  margin: 0 20px;
}
@media screen and (max-width: 1200px) {
  .t--card .header img {
    margin: 0 10px;
  }
}
.t--card .header .name {
  font-weight: 800;
  font-size: 22px;
}
@media screen and (max-width: 1200px) {
  .t--card .header .name {
    font-weight: 800;
    font-size: 18px;
  }
}
.t--card .header .name span {
  display: block;
  font-size: 18px;
  color: #777;
  font-weight: normal;
}
@media screen and (max-width: 1200px) {
  .t--card .header .name span {
    font-size: 14px;
  }
  .t--card {
    padding: 30px;
  }
}
.t--card p {
  color: #777;
  line-height: 1.6;
  padding: 16px 0;
}
.t--card .stars {
  font-size: 30px;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .t--card p {
    font-size: 14px;
  }
  .t--card .stars {
    font-size: 20px;
  }
}
.exemple,
.slidebtn {
  position: absolute;
  top: 50px;
  right: 50px;
}
@media (max-width: 767px) {
  .exemple pre {
  }
}
.slidebtn button {
  padding: 10px 20px;
  outline: 0;
  border: 0;
}
.slidebtn button:hover {
  background-color: #e3e3e3;
}
.slidebtn > button {
  margin-right: 10px;
}
.testimonials .container {
  position: relative;
}

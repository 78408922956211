* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --orange-color: #ff8410;
  --hover-color: #ff9532;
  --gradient-color: linear-gradient(to right, #ff5050 0%, #ff9532 100%);
  --hover-gradient-color: linear-gradient(to right, #ff9532 0%, #ff5050 100%);
  --link-color: #353535;
}
/* :root {
  --orange-color: #fd7d02;
  --hover-color: #ff9532;
  --gradient-color: linear-gradient(to right, #ff5050 0%, #ff9532 100%);
  --hover-gradient-color: linear-gradient(to right, #ff9532 0%, #ff5050 100%);
  --link-color: #353535;
} */
* {
  user-zoom: none;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}
/*Start web-size===========*/
.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin: auto;
}
/*SMAll===============*/
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
/*MEDIUM============*/
@media (min-width: 992) {
  .container {
    width: 970px;
  }
}
/*LARGE=============*/
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
/*End web-size===========*/
/* Start Componants */
.main-heading {
  padding: 20px 0;
  font-weight: 800;
  font-size: 40px;
  position: relative;
  width: fit-content;
  margin: 20px 0;
  user-select: none;
}
.main-heading::before {
  content: "";
  position: absolute;
  height: 6px;
  bottom: 0;
  left: 0;
  width: 70%;
  background-color: var(--orange-color);
  transition: 0.3s;
}
.main-heading:hover.main-heading::before {
  width: 100%;
}
/* End Componants */

.contact {
  background-color: var(--orange-color);
  padding: 50px 0;
  margin: 50px 0 0;
  border-bottom: 1px solid white;
}
.contact .container {
  text-align: center;
  color: white;
}
.contact .container h4 {
  font-weight: normal;
  font-size: 18px;
}
.contact .container p {
  font-size: 40px;
}
.contact p span {
  display: block;
}
.contact .container .email {
  margin: 20px 0;
  margin-bottom: 50px;
}
.contact .container a {
  background-color: white;
  padding: 20px 150px;
  color: #000;
  border-radius: 50px;
  transition: 0.3s;
  font-weight: 800;
  transition: 0.3s;
}
.contact .container a:hover {
  box-shadow: 0px 3px 10px 5px rgb(209 209 209 / 60%);
  color: white;
  background-color: var(--orange-color);
  outline: 1px solid white;
}
@media screen and (max-width: 767px) {
  .contact .container a {
    background-color: white;
    padding: 20px 60px;
    color: #000;
    border-radius: 50px;
  }
}
.contact .container .lets-talk a {
  margin: 30px 0;
  border-radius: 30px;
  padding: 0.6rem 1.3rem;
  outline: 1px solid white;
  width: fit-content;
  margin: auto;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

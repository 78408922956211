.home .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 70vh;
}
.home .container .homeText {
  width: 50%;
}
@media (max-width: 767px) {
  .home {
    width: 100%;
  }
  .home .container {
    flex-direction: column;
  }
  .home .container .homeText {
    padding: 0px 0 30px 0;
    text-align: center;
    width: 80%;
  }
  .home .container .homeText p {
    width: 100%;
    margin: 0 auto;
  }
}
.home .container .homeText h1 {
  color: var(--orange-color);
  padding: 20px 0;
  font-size: 60px;
}
@media (max-width: 1200px) {
  .home .container .homeText h1 {
    font-size: 40px;
  }
}
.home .container .homeText h1 span {
  display: block;
}

.home .container .homeText p {
  line-height: 1.6;
  width: 90%;
  width: 100%;
  color: #777;
  padding: 20px 0;
}
@media (max-width: 1200px) {
  .home .container .homeText p {
    line-height: 1.2;
    font-size: 16px;
  }
}
.home .container .homeText button {
  padding: 0.8rem 1.6rem;
  background: var(--gradient-color);
  color: #fff;
  border: none;
  outline: none;
  border-radius: 22px;
  cursor: pointer;
  transition: 0.3s;
}
.home .container .homeText button:hover {
  background: var(--hover-gradient-color);
}
.home .container .image {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .home .container .image {
    width: 350px;
    height: 350px;
  }
}
.home .container .image img {
  max-width: 100%;
  float: right;
}

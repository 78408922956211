.services {
  padding-top: 100px;
  padding-bottom: 100px;
}
/* .service-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
} */
.service-card .icon {
  font-size: 60px;
  color: var(--orange-color);
}
.service-card h1 {
  font-weight: 800;
  font-size: 32px;
  padding: 20px 0;
}
.service-card p {
  color: #777;
  line-height: 1.8;
  width: 90%;
}
.service-footer {
  padding-top: 60px;
}
.service-footer span {
  color: var(--orange-color);
  font-size: 32px;
  font-weight: 800;
}
.service-footer h4 {
  padding-top: 10px;
  font-size: 20px;
  font-weight: normal;
}
@media (max-width: 767px) {
  .service-card {
    text-align: center;
    margin: 30px 0;
  }
  .service-card p {
    margin: 0 auto;
  }
  .service-footer h4 {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--orange-color);
  }
}
